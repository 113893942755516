import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';

const logger = new Logger('filter-dialog');
const contactModule = namespace('contact');

@Component({
  name: 'filter-dialog',
})
export default class FilterDialog extends Vue {
  @Ref('observer-filter-dialog-form')
  private observerFilterDialogForm!: any;

  @contactModule.Action('getContacts')
  private actionGetContacts!: any;

  @contactModule.Action('updateSearchData')
  private actionUpdateSearchData!: any;
  @contactModule.Getter('getSearchData')
  private searchDataGetter!: any;

  @Prop({ default: false })
  private dialog!: boolean;

  searchData:any = {};

  mounted() {
    this.searchData = {...this.searchDataGetter};
  }

  isSaving = false;
  activeState = [
    {
      text: this.$i18n.t(`employee_status_select.all`), //'Alle',
      value: null,
    },
    {
      text: this.$i18n.t(`employee_status_select.active`), //'Ja',
      value: true,
    },
    {
      text: this.$i18n.t(`employee_status_select.inactive`), //'Nein',
      value: false,
    },
  ];

  meinVmeState = [
    {
      text: this.$i18n.t(`mein_vme_status_select.all`), //'Alle',
      value: null,
    },
    {
      text: this.$i18n.t(`mein_vme_status_select.active`), //'Ja',
      value: true,
    },
    {
      text: this.$i18n.t(`mein_vme_status_select.inactive`), //'Nein',
      value: false,
    },
  ];

  changeActivity(value: any){
    console.log('changeActivity value:>> ', value);
    this.searchData.isActive = value;
  }

  changeMeinVme(value: any){
    console.log('changeMeinVme value:>> ', value);
    this.searchData.isMeinVme = value;
  }

  filter() {
    this.actionUpdateSearchData(this.searchData)
      .then(() => {
        this.clickClose();
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  clickClose() {
    this.$emit('click:close');
  }

  get labelTitel(){
    console.log('-------------------labelTitellabelTitellabelTitellabelTitellabelTitellabelTitellabelTitel-----------------------------------------  ===>>>  this.$route.fullPath ', this.$route.fullPath);
    if (this.$route.fullPath.includes('/suppliers')) {
      return  this.$t('supplier_status');
    } else {
      return  this.$t('employee_status');
    }
  }
}
