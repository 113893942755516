import { Contact as ContactModel } from '@/shared/model/contact';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace, Action } from 'vuex-class';
import Contact from './contact/contact.vue';
import { SearchParams } from '@/shared/model/searchParams';
import DeactivateDialog from './deactivate-dialog/deactivate-dialog.vue';
import FilterDialog from './filter-dialog/filter-dialog.vue';
import DocumentsConditionsDialog from './documents-conditions-dialog/documents-conditions-dialog.vue';
import searchDataFile, { SearchData, FilterContactType } from '@/shared/model/smallPayloadModels/searchData';
import { OdataItems } from '@/shared/model/OdataItems';
import { GspUserRole } from '@/shared/model/gspUserRole';
import { GspUserRoleDetailInner } from '@/shared/model/gspUserRoleDetail';
import { CompanyZrResult } from '@/shared/model/company';
import GeneralUtils, { removeDuplicates } from '@/shared/utils/generalUtils';
import { GspRole } from '@/shared/model/gspRole';

const logger = new Logger('contact-list');
const contactModule = namespace('contact');
const companyModule = namespace('company');
const authModule = namespace('auth');
const gspRoleModule = namespace('gspRole');
const gspServiceModule = namespace('gspService');
const gspUserRoleModule = namespace('gspUserRole');

@Component({
  components: {
    Contact,
    DeactivateDialog,
    FilterDialog,
    DocumentsConditionsDialog,
  },
})
export default class ContactList extends Vue {
  @authModule.Action('canSeeAll')
  private actionCanSeeAll!: any;
  @authModule.Action('addInAzureHausAdminGroup')
  private actionAddInAzureHausAdminGroup!: any;
  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroup!: any;
  @authModule.Getter('isUserSupportOrSuperAdmin')
  private isUserSupportOrSuperAdmin!: any;
  @authModule.Getter('isUserSuperAdmin')
  private isUserSuperAdmin!: any;

  @companyModule.Action('getCompanys')
  private actionGetCompanys!: any;

  @contactModule.Action('getContacts')
  private actionGetContacts!: any;
  @contactModule.Action('getCompanyZr')
  private actionGetCompanyZrNums!: any;
  @contactModule.Action('getContactsExcelReport')
  private actionGetContactsExcelReport!: any;
  @contactModule.Action('updateContactAdditionalInfo')
  private actionUpdateContactAdditionalInfo!: any;
  @contactModule.Action('updateSearchData')
  private actionUpdateSearchData!: any;
  @contactModule.Getter('getContacts')
  private getContacts!: any;
  @contactModule.Getter('getContactsIsLoading')
  private contactsIsLoading!: boolean;
  @contactModule.Getter('getContactsSearchParams')
  private contactsSearchParams!: SearchParams;
  @contactModule.Getter('getSearchData')
  private getSearchData!: SearchData;

  @gspRoleModule.Action('getGspRoles')
  private actionGetGspRoles!: any;
  @gspRoleModule.Getter('getGspRoles')
  private gspRoles!: OdataItems<GspRole>;

  @gspServiceModule.Action('getGspServices')
  private actionGetGspServices!: any;
  @gspServiceModule.Getter('getGspServices')
  private gettterGspServices!: any;

  @contactModule.Getter('getCompanyZrsIsLoading')
  private companyZrsIsLoading!: any;
  @contactModule.Action('updateCompanyZrIsLoading')
  private updateCompanyZrIsLoading!: any;
  get userHasCompany() {
    return this.сompanyZrs.length > 0;
  }
  get userCompanyLoaded() {
    return this.companyZrsIsLoading === false;
  }

  get isFilterUsed() {
    return this.getSearchData && (this.getSearchData.isActive !== null || this.getSearchData.isMeinVme !== null);
  }

  // TODO(GSP-108): load every distinct user role on open the contact later
  @gspUserRoleModule.Action('getGspUserRoles')
  private actionGetGspUserRoles!: any;
  @gspUserRoleModule.Getter('getGspUserRoles')
  private gspUserRoles!: OdataItems<GspUserRole>;
  @gspUserRoleModule.Getter('getGspUserRolesActiveAndNot')
  private gspUserRolesActiveAndNot!: OdataItems<GspUserRole>;
  @gspUserRoleModule.Getter('getGspUserRolesAllForAdmin')
  private gspUserRolesAllForAdmin!: OdataItems<GspUserRole>;
  @gspUserRoleModule.Getter('getGspUserRolesIsLoading')
  private gspUserRolesIsLoading!: any;

  @contactModule.Getter('getSearchData')
  private searchData!: SearchData;

  @Watch('searchData', { deep: true })
  @Watch('contactsSearchParams.filter', { deep: true })
  public async onOptionsFilterChanged(newVal: SearchData, oldVal: SearchData) {
    // console.log('onOptionsFilterChanged newVal :>> ', newVal);
    // console.log('onOptionsFilterChanged oldVal :>> ', oldVal);
    // console.log('newVal :>> ', newVal);
    // console.log('object :>> ', newVal == oldVal);

    // (AD-151) avoid extra queries for `Contacts`
    if (GeneralUtils.areObjectsEqual(newVal, oldVal)) {
      console.log('onOptionsFilterChanged ObjectsEqual :>> ' );
      return;
    }

    logger.debug(`----filter:${oldVal}->${newVal}`);
    this.contactsSearchParams.dataOption.page = 1;
    const payload = {
      searchParams: this.contactsSearchParams,
      searchData: {
        isActive: newVal.isActive || this.searchData.isActive, // (AD-92) when filter by searchTerm in `text-field` take default value from Getter (then newVal is text value from `searchTerm` )
        isMeinVme: newVal.isMeinVme || this.searchData.isMeinVme, //this.companyZrNumbers || [],
        companyZr: newVal.companyZr || this.searchData.companyZr, //this.companyZrNumbers || [],
        contactIds: newVal.contactIds || this.searchData.contactIds,
        contactTypes: newVal.contactTypes || this.searchData.contactTypes,
        contact_Type: newVal.contact_Type || this.searchData.contact_Type,
      },
    };

    console.log('------------------------onOptions FilterChanged-----------------------action GetContacts-------------------------------------------------------------------------------------  ===>>> searchData  ', this.searchData, '   payload ', payload);
    await this.actionGetContacts(payload)
      .then(async (result: any) => {
        // console.log('result getContactsData onOptionsFilterChanged:>> ', result);
        const contactIds = result?.value.map((x: any) => x.recordID);
        // console.log('contactIds getContactsData onOptionsFilterChanged:>> ', contactIds);

        const payload = {
          contactIds: contactIds,
          isUserSupportOrSuperAdmin: this.isUserSupportOrSuperAdmin,
        };

        await this.getGspUserRoles(payload);
        let contactType: string = this.$route.fullPath.includes('/supplier') ? 'supplier' : '';
        console.log('------------------on OptionsFilterChanged------------------------------------CALL --get CompanyZrsFromBackend----------------------------------------------------------------------');
        await this.getCompanyZrsFromBackend(contactType);
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private pagination: {
    page: number;
    itemsPerPage: number;
    pageStart: number;
    pageStop: number;
    pageCount: number;
    itemsLength: number;
  } = { page: 1, itemsPerPage: 50, pageStart: 1, pageStop: 0, pageCount: 0, itemsLength: 0 };
  // pageStart is start of page on the X page, pageStop is the end of page on the X page like `151-200 von 4720` for the 4th page with the `itemsPerPage: 50` in the footer text

  private previousSearchParam: any = {};
  private async contactsUpdateOptions(newVal: any, oldVal: any) {
    console.log(  '***************************************************************------------------------------------------------------------------------contacts-------------------UpdateOptions----------------------------------------------  ===>>>   '    );
    console.log('newVal contactsUpdateOptions :>> ', newVal);
    console.log('oldVal :>> ', oldVal); // !! `oldVal` always `undefined`

    // (AD-151) avoid extra queries for `Contacts`
    if (GeneralUtils.areObjectsEqual(this.previousSearchParam, newVal)) {
      console.log('newVal contactsUpdateOptions contactsUpdateOptionsect  THE SAMEEEEEEEEEE:>> ');
      return;
    }
    this.previousSearchParam = Object.assign(newVal);

    this.contactsSearchParams.dataOption.page = newVal.page;
    this.contactsSearchParams.dataOption.itemsPerPage = newVal.itemsPerPage;
    // this.employeesSearchParams.filter = newVal.filter;
    if (newVal != oldVal && this.contactsIsLoading === false && this.isUserInAzureAdminGroup){
      this.getContactsSearch();
      let contactType: string = this.$route.fullPath.includes('/supplier') ? 'supplier' : '';
      console.log('------------------ contacts UpdateOptions------------------------------------CALL --get CompanyZrsFromBackend----------------------------------------------------------------------');
      await this.getCompanyZrsFromBackend(contactType);
    }
  }

  private searchTerm = '';
  private companyZrNumbers = '';
  private searchByGspRoleIds: number[] = [];
  private searchByContactTypes: FilterContactType[] = [];
  searchCompanyZr = '';
  searchGspRole = '';
  сompanyZrs: CompanyZrResult[] = [];

  private resetSearchCompanyZr(): void {
    this.searchCompanyZr = '';
  }
  private resetSearchGspRole(): void {
    this.searchGspRole = '';
  }

  clearSearchCompanyZrInput(): void {
    this.searchData.companyZr = [];
    this.resetSearchCompanyZr();
  }
  clearSearchGspRoleInput(): void {
    this.searchData.contactIds = null;
    this.resetSearchGspRole();
  }

  private onBlurSearchCompanyZr(): void {
    this.resetSearchCompanyZr();
  }
  private onBlurSearchGspRole(): void {
    this.resetSearchGspRole();
  }

  private onSearchByGspRoleUpdated(): void {
    console.log(
      '-------------------------------------------on Search ByGspRoleUpdated----------------------------------------------  ===>>> this.searchByGspRoleIds ',
      this.searchByGspRoleIds
    );
    if (this.searchByGspRoleIds.length == 0) {
      this.searchData.contactIds = null;
      return;
    }

    // Search for gspUser roles that include at least one of the gsp roles selected in the filter.
    // This is possible because all GspUserRoles are cached on the frontend.
    let filterFunction = (gspUserRole: GspUserRole) => {
      if (!gspUserRole.contactId) {
        return false;
      }

      for (let gspRoleId of this.searchByGspRoleIds) {
        let searchResult = gspUserRole.gspUserRoleDetails.find((x) => {
          let roleDetails = x.roleDetails as GspUserRoleDetailInner[];
          if (roleDetails == undefined) return false;

          let searchResultInner1 = roleDetails.find((y) => {
            let moduleRoles = y.moduleRoles;
            if (moduleRoles == undefined) return false;

            let searchResultInner2 = moduleRoles.find((z) => {
              let roles = z.roles;
              if (roles == undefined) return false;

              return roles.includes(gspRoleId);
            });

            return searchResultInner2 != undefined;
          });

          return searchResultInner1 != undefined;
        });

        if (searchResult != undefined) {
          return true;
        }
      }

      return false;
    };
    console.log(
      '-------------------------------------------on Search ByGspRoleUpdated----------------------------------------------  ===>>>  filterFunction ',
      filterFunction,
      '   this.gspUserRolesAllForAdmin  ',
      this.gspUserRolesAllForAdmin,
      '   gspUserRolesActiveAndNot  ',
      this.gspUserRolesActiveAndNot
    );
    // Select all ids of contacts that include one of the roles selected in the filter.
    if (this.isUserSupportOrSuperAdmin) {
      this.searchData.contactIds = this.gspUserRolesAllForAdmin.items.filter(filterFunction).map((x) => x.contactId!);
    } else {
      this.searchData.contactIds = this.gspUserRolesActiveAndNot.items.filter(filterFunction).map((x) => x.contactId!);
    }
  }

  private onSearchByContactTypeUpdated() {
    if (this.searchByContactTypes.length == 0) {
      this.searchData.contactTypes = null;
      return;
    }

    this.searchData.contactTypes = this.searchByContactTypes;
  }

    async created() {
    this.actionUpdateSearchData(searchDataFile.defaultData());
    // console.log('CREATED this.searchData :>> ', Object.assign(this.searchData));
    this.updateCompanyZrIsLoading(undefined); // (AD-158) fix blinking `Kein Firma` message when switch between `Benutzer/Lieferanten`
    this.contactsSearchParams.filter = '';
    console.log(
      '*CONTACT-LIST*********---crea-ted---***********  ===>>>   ',
      this.$route,
      ' route Path  ',
      this.$route.path,
      ' route fullPath  ',
      this.$route.fullPath,
      '  this.isUserSupportOrSuperAdmin  ', this.isUserSupportOrSuperAdmin
    );
    await this.loadLogic();
  }

  mounted() {
    if (this.$route.fullPath.includes('/suppliers')) {
      this.searchData.contact_Type = 'supplier';
    } else {
      this.searchData.contact_Type = '';
    }
  }

  private canSeeCall = 1;
  async loadLogic() {
    await this.actionCanSeeAll().catch((err: any) => {
      // on 1st login using Azure logic the request to `actionCanSeeAll` to Azure return token error so send the same request after 2 seconds (need only on 1st login)
      logger.error(err);
      if (this.canSeeCall >= 3) {
        logger.error('Azure different error: ' + err);
        return;
      }
      setTimeout(async () => {
        ++this.canSeeCall;
        await this.loadLogic();
      }, 2000 * this.canSeeCall);
    });
    if (this.isUserInAzureAdminGroup) {
      let contactType: string = this.$route.fullPath.includes('/supplier') ? 'supplier' : '';
      console.log('------------------ loadLogic------------------------------------CALL --get CompanyZrsFromBackend----------------------------------------------------------------------');
      await this.getCompanyZrsFromBackend(contactType); // (AD-145) load 1st to avoid long loading all other data, otherwise see progress bar
      await this.actionGetGspRoles({contactType: contactType});

      await this.getContactsData()
        .then(async (result) => {
          console.log('result getContactsData:>> ', result);
          let searchDataNewObj = Object.assign({}, this.searchData);
          const contactIds = result?.value.map((x: any) => x.recordID);
          // console.log('contactIds getContactsData:>> ', contactIds);

          const payload: any = {
            contactIds: contactIds,
            isUserSupportOrSuperAdmin: this.isUserSupportOrSuperAdmin,
            isInitialRequest: searchDataNewObj.isInitialRequest,
          };

          await this.getGspUserRoles(payload)
            .then((result: any) => {
              if (this.isUserSupportOrSuperAdmin) {
                payload.isGetAll = true;
                this.getGspUserRoles(payload); // (AD-139) for Admin load all records to filter among all records
              }
            })
            .catch((err) => {
              logger.error(err);
            });
        })
        .catch((err) => {
          logger.error(err);
        });

      await this.actionGetGspServices({contactType: contactType});
    }
  }

  async getGspUserRoles(payload: any) {
    await this.actionGetGspUserRoles(payload)
      .then((result: any) => {
        logger.log('result getGspUserRoles :>> ', result);
        console.log('this.gspUserRoleDetailsIsLoading :>> ', this.gspUserRolesIsLoading);
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }
  async getCompanyZrsFromBackend(contactType: string) {
    const payload = {
      isInSuperAdminOrSupportGroup: false,
      contactType: contactType,
      IsActive: this.searchData.isActive,
    };
    await this.actionGetCompanyZrNums(payload)
      .then((result: CompanyZrResult[]) => {
        logger.log('result :>> ', result);
        this.сompanyZrs = result;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private async getContactsData() {
    // don't take getter value in this case
    return await this.actionGetContacts({ searchParams: this.contactsSearchParams, searchData: this.searchData })
      .then(async (result: any) => {
        logger.log('result :>> ', result);
        return result;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private changeSearch(searchTerm: string) {
    if (searchTerm.length < 2) return;
    this.searchContacts(searchTerm);
  }
  private changeSearchInput(value: string) {
    if (value.length == 0) {
      this.resetSearch();
    }
  }
  private resetSearch() {
    const emptyString = '';
    this.searchContacts(emptyString);
  }

  private async searchContacts(filterValue: string) {
    this.contactsSearchParams.filter = filterValue; // `this.get ContactsSearch` will be called via `@Watch`
    // if (!this.contactsIsLoading) this.get ContactsSearch();
  }
  private async getContactsSearch() {
    console.log(
      '---------------------------------------------------------------------on get ContactsSearch------action GetContacts----------------------------------------  ===>>>  this.searchData  ',
      this.searchData
    );
    await this.actionGetContacts({ searchParams: this.contactsSearchParams, searchData: this.searchData });
  }

  get filterContactTypes() {
    return Object.values(FilterContactType).map((x) => ({ value: x, text: this.$t(`filterContactTypes.${x}`) }));
  }

  get sortedGspRoles() {
    let gspRoles = [...this.gspRoles.items];

    gspRoles.sort((gspRole1, gspRole2) => {
      let serviceName1 = removeDuplicates(gspRole1.rolesData.map((x) => x.service.name)).join(',');
      let serviceName2 = removeDuplicates(gspRole2.rolesData.map((x) => x.service.name)).join(',');

      if (serviceName1 > serviceName2) return 1;
      if (serviceName2 > serviceName1) return -1;

      if (gspRole1.name > gspRole2.name) return 1;
      if (gspRole2.name > gspRole1.name) return -1;

      return 0;
    });

    return gspRoles;
  }

  //#region Sorting and Expand/collapse buttons logic
  private isExpandAll = false;

  get IsSortAscending(): boolean {
    return this.contactsSearchParams.dataOption.sortDesc === false;
  }

  private sortContacts() {
    console.log(
      '-------------------------------------------------------------------------------------sort Contacts------------------  ===>>>   '
    );
    this.contactsSearchParams.dataOption.sortDesc = !this.contactsSearchParams.dataOption.sortDesc;
    this.getContactsSearch();
  }

  private getGspRoleLabel(gspRole: GspRole): string {
    if (!gspRole) return '';
    if (!gspRole.rolesData?.length) return gspRole.name;
    return `${removeDuplicates(gspRole.rolesData.map((x) => x.service.name)).join(',')} - ${gspRole.name}`;
  }

  private expandCollapseAllContacts() {
    let expansions = document.querySelectorAll<HTMLElement>(
      '.v-expansion-panel-header:not(.v-expansion-panel-header--active)'
    );
    if (this.isExpandAll) {
      expansions = document.querySelectorAll<HTMLElement>('.v-expansion-panel-header.v-expansion-panel-header--active');
    }

    expansions.forEach((element) => {
      if (element instanceof HTMLElement) {
        element.click();
      }
    });
    this.isExpandAll = !this.isExpandAll;
  }
  //#endregion

  deactivateUser(contact: ContactModel): void {
    logger.log('(this.isUserSupportOrSuperAdmin :>> ', this.isUserSuperAdmin);
    if (contact.isActive || this.isUserSuperAdmin) {
      this.dialogDeactivate.model = contact;
      this.showDeactivateDialog();
    }
  }

  async changeHouseAdminStatusOfUser(contact: ContactModel) {
    await this.actionUpdateContactAdditionalInfo(contact)
      .then((result: any) => {
        // console.log('actionUpdateContactAdditionalInfo :>> ', result);
        const payload = {
          azureUserId: contact.contactAzureId,
          isHouseAdmin: result.result.isHouseAdmin,
        };
        logger.log(' addInAzureHausAdminGroup payload :>> ', payload);

        // return error for empty `contact.contactAzureId`
        if (!contact.contactAzureId) {
          logger.error('Cannot add contact in Azure group if `Contact` does not have `contactAzureId` field');
          return;
        }

        this.actionAddInAzureHausAdminGroup(payload)
          .then((result: any) => {
            // NO result (response 204 `No Content`) both for add/remove Azure User in Azure Group // https://learn.microsoft.com/en-us/graph/api/group-post-members
          })
          .catch((err: any) => {
            logger.error(err);
          });
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  //#region Deactivate-dialog
  dialogDeactivate = {
    show: false,
    model: {},
    OnClose: (): void => {
      this.dialogDeactivate.show = false;
    },
  };

  showDeactivateDialog(): void {
    logger.log('show-deactivate-dialog');
    this.dialogDeactivate.show = true;
  }
  //#endregion

  //#region Filter-dialog
  dialogFilter = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogFilter.show = false;
    },
  };

  showFilterDialog() {
    logger.log('show-filter-dialog');
    this.dialogFilter.show = true;
  }

  showFilter() {
    this.dialogFilter.show = true;
  }
  //#endregion

  isLoadingFile = false;
  downloadExcel() {
    const payload = {
      searchParams: this.contactsSearchParams,
      searchData: {
        isActive: this.searchData.isActive,
        companyZr: this.searchData.companyZr,
        contactIds: this.searchData.contactIds,
        contactTypes: this.searchData.contactTypes,
        lang: this.$i18n.locale,
        contact_Type:this.searchData.contact_Type,
      },
    };

    this.isLoadingFile = true;
    this.actionGetContactsExcelReport(payload).finally(() => {
      this.isLoadingFile = false;
    });
  }

  //#region Documents-conditions-dialog
  dialogDocumentConditions: any = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogDocumentConditions.show = false;
    },
    UpdateContactPanelId: (id: any) => {
      this.updateModel(id);
    },
  };

  updateModel(id: any) {
    logger.log('panel id :>> ', id);
    this.dialogDocumentConditions.model.contactPanelId = id;
  }

  showDocumentConditionsDialog(contact: ContactModel): void {
    logger.log('show-filter-dialog');
    this.dialogDocumentConditions.model = contact;
    this.dialogDocumentConditions.show = true;
  }

  get titleSearch() {
    if (this.$route.fullPath.includes('/suppliers')) {
      return this.$t('searchSuppliers_label');
    } else {
      return this.$t('search_label');
    }
  }

  get titleCount() {
    if (this.$route.fullPath.includes('/suppliers')) {
      return  this.$t('contactsSuppliers_count', {0: this.getContacts.total});
    } else {
      return  this.$t('contacts_count', {0: this.getContacts.total});
    }
  }

  get loadingContacts(){
    if (this.$route.fullPath.includes('/suppliers')) {
      return  this.$t('loadingSuppliers_contacts');
    } else {
      return  this.$t('loading_contacts');
    }
  }

  //#endregion
}
